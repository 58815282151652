export default function home_hero() {
	
  $('.block-hero .owl-carousel').owlCarousel({
    items: 1,
    dots: true,
    loop: true,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    navText: ['<i class="fal fa-chevron-left"></i><span class="sr-only">Prev</span>','<i class="fal fa-chevron-right"></i><span class="sr-only">Next</span>'],
	onInitialized: addDotButtonText,
    onResized: addDotButtonText
  });
  
  function addDotButtonText() {
		// loop through each dot element
		$('.owl-dot').each(function() {
			// remove old text nodes
			$(this).find('.sr-only').remove();

			// grab its (zero-based) order in the series
			let idx = $(this).index() + 1;

			// append a span to the button containing descriptive text
			$(this).append('<span class="sr-only">Go to slide ' + idx + '</span>');
		});
	}
}