export default function home_artists() {
	
  $('.block-artists .artists-tabs').tabs();

  $('.block-artists .owl-carousel').owlCarousel({
    items: 2.5,
    margin: 20,
    dots: false,
    loop: true,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    navText: ['<i class="fal fa-chevron-left"></i><span class="sr-only">Prev</span>','<i class="fal fa-chevron-right"></i><span class="sr-only">Next</span>'],
    responsive: {
      768 : {
        items: 4
      }
    }
  });
	
}