export default function artists() {

  $('.artists-regions a').click(function(e){
    e.preventDefault();

    $('.artists-regions a').removeClass('active');
    $(this).addClass('active');

    $('.artists-list').html('');
    $('.loading').show();

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.loading').hide();
        $('.artists-list').html( $(res).find('.artists-list').html() );
        initLoadNext();
      }
    });
  });

  initLoadNext();
  initInfiniteScroll();

  function initLoadNext() {
    $('.load-next a').click(function(e){
      e.preventDefault();
      $('.loading').show();
  
      $.ajax({
        url: $(this).attr('href'),
        type: 'GET',
        success: function(res) {
          $('.loading').hide();
          $('.artists-list').append( $(res).find('.artists-list').html() );
          initLoadNext();
        }
      });
    });
  }

  function initInfiniteScroll() {
    $(window).on('scroll', function() {
      if ($('.load-next a').length == 0) return false;
      
      const pos = $(document).scrollTop();
      if ($('.load-next a').offset().top < pos + $(window).height() + 50) {
        $('.load-next a').click();
        $('.load-next').remove();
      }
    });
  }
  
}