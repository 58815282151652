export default function nav() {

  $('#main').css('min-height', $(window).height() - $('#header').height() - $('#footer').height() - 100);
	
	$('#header .hamburger').on('click', function (e) {
		e.preventDefault();
		$( this ).toggleClass( 'is-active' );
		$( '.nav-container' ).toggleClass( 'active' );
  });
  
}