require( 'owl.carousel' );

import nav from './modules/nav';
import home_hero from './modules/home_hero';
import home_songs from './modules/home_songs';
import home_artists from './modules/home_artists';
import home_podcasts from './modules/home_podcasts';
import home_playlists from './modules/home_playlists';
import artists from './modules/artists';
import contact from './modules/contact';

$(document).ready(function($) {

  nav();
  // contact();

  if ($('body').hasClass('home')) {
    home_hero();
    home_songs();
    home_artists();
    home_podcasts();
    home_playlists();
  }

  if ($('body').hasClass('page-template-artists')) {
    artists();
  }

});